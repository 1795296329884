import { combineReducers } from "redux";
import adminReducer from "./admin/reducer";
import spinnerReducer from "./spinner/reducer";
import { sellerReducer } from "./seller/seller.reducer";
import { historyReducer } from "./history/history.reducer";
import diamondShopReducer from "./diamondShop/reducer";

export default combineReducers({
  admin: adminReducer,
  history:historyReducer,
  sellerCoin :sellerReducer,
  spinner: spinnerReducer,
  diamondShop: diamondShopReducer
});
