import axios from "axios";
import { Toast } from "../../util/Toast";
import {
  GET_SHOP_DIAMOND,
  BUY_DIAMOND,
} from "./types";

export const getShopDiamond = () => (dispatch) => {
  axios
    .get(`shopDiamond?forSeller=true`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_SHOP_DIAMOND, payload: res.data.shopDiamonds });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const buyDiamond = (data) => (dispatch) => {
  axios
  .post(`payment`, data)
  .then((res) => {
    if (res.data.status) {
      dispatch({ type: BUY_DIAMOND, payload: res.data.data });
      window.location.href = res.data.data.invoice_url;
    } else {
      Toast("error", res.data.message);
    }
  }).catch((error) => Toast("error", error.message));
}