import axios from "axios";
import * as ActionType from "./history.type";

// get admin history

export const getAdminHistory = (id , start , limit) => (dispatch) => {
  
  axios
    .get(`coinSellerHistory/coinSeller/${id}?start=${start}&limit=${limit}`)
    .then((res) => {
      
      console.log(res.data);
      dispatch({
        type: ActionType.GET_ADMIN_HISTORY,
        payload: res.data
      });
    })
    .catch((error) => console.log("error", error));
};



