import {
  CLOSE_DIAMOND_SHOP_DIALOG,
  GET_SHOP_DIAMOND,
  OPEN_DIAMOND_SHOP_DIALOG,
  BUY_DIAMOND,
} from "./types";

const initialState = {
  shopDiamond: [],
  invoice: {},
  dialog: false,
};

const diamondShopReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SHOP_DIAMOND:
      return {
        ...state,
        shopDiamond: action.payload,
      };
    case BUY_DIAMOND:
      return {
        ...state,
        invoice: action.payload,
      };
    case OPEN_DIAMOND_SHOP_DIALOG:
      return {
        ...state,
        dialog: true,

      };
    case CLOSE_DIAMOND_SHOP_DIALOG:
      return {
        ...state,
        dialog: false,
      };

    default:
      return state;
  }
};

export default diamondShopReducer;
