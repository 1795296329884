export const SET_ADMIN = "SET_ADMIN";
export const UNSET_ADMIN = "UNSET_ADMIN";

export const SEND_EMAIL = "SEND_EMAIL";

export const UPDATE_PROFILE = "UPDATE_PROFILE";

// signup
export const SIGNUP_ADMIN = "SIGNUP_ADMIN";

export const GET_SELLER = "GET_SELLER";
