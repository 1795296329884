import { TablePagination } from "@material-ui/core";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAdminHistory } from "../../store/history/history.action";
import TablePaginationActions from "../../pages/Pagination";

// excel file download

import { useRef } from "react";
import Pagination from "../../pages/Pagination";

const History = (props) => {
  const [data, setData] = useState([]);

  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [type, setType] = useState("admin");

  const { admin } = useSelector((state) => state.admin);
  const { history, totalCoin, total } = useSelector((state) => state.history);

  useEffect(() => {
    if (admin._id) {
      props.getAdminHistory(admin._id, activePage, rowsPerPage);
    }
  }, [activePage, rowsPerPage]);
  const tableRef = useRef();

  useEffect(() => {
    setData(history);
  }, [history]);

  // handleSearch
  const handleSearch = (e) => {
    const value = e.target.value.toUpperCase();
    if (value) {
      const data = history.filter((data) => {
        return (
          data?.userId?.name?.toUpperCase()?.indexOf(value) > -1 ||
          data?.userId?.country?.toUpperCase()?.indexOf(value) > -1 ||
          data?.coin?.toString()?.indexOf(value) > -1
        );
      });
      setData(data);
    } else {
      setData(history);
    }
  };

  // pagination
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };
  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3" style={{ color: "#e4eeff" }}>
              History
            </h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active " aria-current="page">
                  history
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div className="card">
            <div className="p-0">
              <div className=" card-header row justify-content-end my-3 g-0">
                {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 my-4">
                  <form action="">
                    <div className="input-group  border rounded-pill">
                      <input
                        type="search"
                        placeholder="Searching for..."
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        onChange={handleSearch}
                      />
                    </div>
                  </form>
                </div> */}
              </div>

              <div className="row mx-5 ">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 pb-4">
                  <div className="text-primary">
                    <h4 className="fw-bold">History</h4>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 pb-4 d-flex justify-content-end align-items-center text-primary fw-bold fs-5">
                  <h4 className="fw-bold text-primary">
                    Total Diamond :{totalCoin}
                  </h4>
                </div>
              </div>

              <div className="card-body card-overflow pt-0">
                <table
                  className="table table-striped mt-5 text-center"
                  ref={tableRef}
                >
                  <thead>
                    <tr className="text-center">
                      <th className="fw-bold fs-6">ID</th>
                      <th className="fw-bold fs-6">Name</th>
                      <th className="fw-bold fs-6">Unique Id</th>
                      <th className="fw-bold fs-6">diamond</th>
                      <th className="fw-bold fs-6">Created At</th>
                      <th className="fw-bold fs-6">Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length > 0 ? (
                      data?.map((data, index) => {
                        const date = data?.date.split(",");
                        return (
                          <>
                            <tr className="text-center ">
                              <td className="fw-bold text-secondary">
                                {index + 1}
                              </td>
                              <td className="fw-bold fw-dark">
                                {data?.userId ? data?.userId?.name : "Admin"}
                              </td>
                              <td className="fw-bold fw-info">
                                {data?.userId ? data?.userId?.uniqueId : "Admin"}
                              </td>

                              {data?.isIncome ? (
                                <td className="text-success  fw-bold">
                                  + {data?.coin}
                                </td>
                              ) : (
                                <td className="text-danger fw-bold">
                                  - {data?.coin}
                                </td>
                              )}
                              <td className="fw-bold text-info">
                                {dayjs(data?.createdAt).format("DD MMM YYYY")}
                              </td>
                              <td className="fw-bold text-success">
                                {date[1]}
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center">
                          No Data Found !
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Pagination
                  activePage={activePage}
                  rowsPerPage={rowsPerPage}
                  userTotal={total}
                  handleRowsPerPage={handleRowsPerPage}
                  handlePageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getAdminHistory })(History);
