import dayjs from "dayjs";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getUserHistory } from "../../store/seller/seller.action";
import Pagination from "../../pages/Pagination";

const UserHistory = (props) => {
  const { history, total  ,totalCoin} = useSelector((state) => state.sellerCoin);
  const { admin } = useSelector((state) => state.admin);
  const [data, setData] = useState([]);

  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const location = useLocation();
  useEffect(() => {
    props.getUserHistory(location?.state?.id , admin?._id);
  }, [admin]);

  useEffect(() => {
    setData(history);
  }, [history]);
  let historyData = useHistory();

  const goPrevious = () => {
    historyData.goBack();
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  return (
    <>
    <div className="row">
    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pb-4 ">
          <div className="text-primary">
            <h4 className="fw-bold"> Diamond History</h4>
          </div>
        </div>
    </div>
      
      <div className="m-5 row pb-3">
      

        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4  text-primary fw-bold fs-5 align-self-end">
          Total Diamond : {totalCoin ? totalCoin : "0"}
        </div>
        <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-8 text-end">
        {/* <Link to="/admin/user"> */}
        <button className="btn btn-primary btn-icon px-4 " onClick={goPrevious}>
          <i class="fa-solid fa-angles-left text-white fs-6"></i>
        </button>
        {/* </Link> */}
      </div>
      </div>
      <div className="table-responsive">
        <table className="table table-hover card-table table-vcenter text-nowrap">
          <thead className="border-top">
            <tr className="text-center pt-1">
              <th className="fw-bold fs-6">No</th>

              <th className="fw-bold fs-6">Diamond</th>

              <th className="fw-bold fs-6">Date</th>
              <th className="fw-bold fs-6">Time</th>
            </tr>
          </thead>

          <tbody>
            {data?.length > 0 ? (
              data?.map((admin, index) => {
                const date = admin?.date.split(",");
                return (
                  <>
                    <tr className="text-center border-bottom pb-2">
                      <td className="fs-6">{index + 1}</td>

                      <td className="fs-6 text-success">+{admin?.coin}</td>
                      {/* date */}
                      <td className="fs-6 text-info">{date[0]}</td>
                      {/* time */}
                      <td className="fs-6 text-success">{date[1]}</td>
                    </tr>
                  </>
                );
              })
            ) : (
              <tr className="border-bottom pb-2">
                <td colSpan="7" className="text-center">
                  No dataFound !
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          activePage={activePage}
          rowsPerPage={rowsPerPage}
          userTotal={total}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default connect(null, { getUserHistory })(UserHistory);
