import React, { useEffect } from "react";

// routing
import { NavLink as Link } from "react-router-dom";

// alert
import { warning } from "../../util/Alert";

import Tooltip from "@material-ui/core/Tooltip";
// redux
import { useDispatch } from "react-redux";

// types
import { UNSET_ADMIN } from "../../store/admin/types";

//MUI
import { makeStyles } from "@material-ui/core";

// jquery
import $ from "jquery";

const useStyles = makeStyles(() => ({
  navLink: {
    "&.active": {
      color: "#1976d2 !important",
      fontWeight: 500,
      fontSize: 16,
    },
    "&.active span": {
      color: "#1976d2 !important",
      fontWeight: 900,
    },
  },
}));

const Navbar = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleLogout = () => {
    const data = warning();
    data.then((isLogout) => {
      if (isLogout) {
        dispatch({ type: UNSET_ADMIN });
        window.location.href = "/";
      }
    });
  };

  useEffect(() => {
    $("").addClass("submenu-margin");
  }, []);
  return (
    <>
      <div class="page-sidebar">
        <Link to="/admin/dashboard">
          <span className="logo text-danger">VibeLynk</span>
        </Link>
        <ul class="list-unstyled accordion-menu">
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Dashboard"
          >
            <Link to="/admin/dashboard" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="activity"></i>
              </span>
              Dashboard
            </Link>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Buy Diamonds"
          >
            <Link to="/admin/buyDiamonds" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="shopping-cart"></i>
          
              </span>
              Buy Diamonds
            </Link>
          </li>

          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Diamond Seller"
          >
            <Link to="/admin/History" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="rotate-ccw"></i>
          
              </span>
              History
            </Link>
          </li>

          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title=" User History"
          >
            <Link to="/admin/userHistory" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
              <i data-feather="clock"></i>
              </span>
              User History
            </Link>
          </li>

          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Logout">
            <a
              href={() => false}
              onClick={handleLogout}
              className="add-collapse-margin"
            >
              <i data-feather="log-out"></i>Logout
            </a>
          </li>
        </ul>
        <a
          href={() => false}
          id="sidebar-collapsed-toggle"
          style={{ opacity: 0, pointerEvents: "none" }}
        >
          <i data-feather="arrow-right"></i>
        </a>
      </div>
    </>
  );
};
export default Navbar;
