import { TablePagination } from "@material-ui/core";
import dayjs from "dayjs";
import React from "react";
import { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import TablePaginationActions from "../../pages/Pagination";
// import CoinSeller from "../dialog/CoinSeller";
import * as ActionType from "../../store/seller/seller.type";
import { getCoinSellerHistory } from "../../store/seller/seller.action";
import { logDOM } from "@testing-library/react";
import CoinSeller from "../dialog/CoinSeller";
import Pagination from "../../pages/Pagination";

const SellerTable = (props) => {
  const { admin } = useSelector((state) => state.admin);
  const { sellerCoin, total } = useSelector((state) => state.sellerCoin);

  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (admin._id) {
      props.getCoinSellerHistory(admin._id, activePage, rowsPerPage);
    }
  }, [activePage, rowsPerPage]);

  useEffect(() => {
    setData(sellerCoin);
  }, [sellerCoin]);

  const handleSearch = (e) => {
    const value = e.target.value.toUpperCase();
    if (value) {
      const data = sellerCoin.filter((data) => {
        console.log("data", data);
        return (
          data?.name?.toUpperCase()?.indexOf(value) > -1 ||
          data?.country?.toUpperCase()?.indexOf(value) > -1 ||
          data?.sellerCoin?.toString()?.indexOf(value) > -1
        );
      });
      setData(data);
    } else {
      setData(sellerCoin);
    }
  };

  // pagination
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  const dispatch = useDispatch();
  const handleOpen = () => {
    dispatch({ type: ActionType.OPEN_COIN_SELLER_DIALOGUE });
  };

  const history = useHistory();
  const showUserHistory = (id) => {
    //Handle Redirect User History Page

    history.push({ pathname: "/admin/user/history", state: { id } });
  };
  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3" style={{ color: "#e4eeff" }}>
              Seller
            </h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active " aria-current="page">
                  Diamond Seller
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="card" id="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <button
                    type="button"
                    className="btn waves-effect waves-light btn-danger btn-sm float-left"
                    onClick={handleOpen}
                    id="CoinSellerAdd"
                  >
                    <i className="fa fa-plus"></i>
                    <span className="icon_margin">New</span>
                  </button>
                </div>
                {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0">
                  <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <div className="input-group-prepend border-0">
                        <div id="button-addon4" className="btn text-danger">
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                      <input
                        type="search"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        onChange={handleSearch}
                      />
                    </div>
                  </form>
                </div> */}
              </div>
            </div>
            <div class="card-body card-overflow pt-0">
              <table class="table table-striped mt-5 text-center">
                <thead>
                  <tr className="text-center">
                    <th className="fw-bold fs-6">ID</th>
                    <th className="fw-bold fs-6">Image</th>
                    <th className="fw-bold fs-6">name</th>
                    <th className="fw-bold fs-6">Unique Id</th>
                    <th className="fw-bold fs-6">Gender</th>
                    <th className="fw-bold fs-6">Country</th>
                  
                    <th className="fw-bold fs-6"> Receive Diamond </th>
                    <th className="fw-bold fs-6"> Give Diamond Date</th>

                    <th className="fw-bold fs-6">History</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.length > 0 ? (
                    data?.map((data, index) => {
                      return (
                        <>
                          <tr className="text-center fw-bold">
                            <td className="text-dark">{index + 1}</td>
                            <td>
                              <img
                                src={data?.image}
                                width={50}
                                height={50}
                                alt="hostImage"
                                className="mx-auto table_image "
                                draggable="false"
                              />
                            </td>
                            <td className="text-info">{data?.name}</td>
                            <td className="text-dark">{data?.uniqueId}</td>
                            <td className="text-danger">{data?.gender}</td>
                            <td className="text-warning ">{data?.country}</td>
                         
                            <td className="text-success">
                              +{data?.sellerCoin}
                            </td>
                            <td className="text-primary">
                              {dayjs(data?.date).format("DD MMM YYYY")}
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-primary btn-icon"
                                onClick={() => showUserHistory(data?._id)}
                              >
                                <i
                                  class="fas fa-history px-2"
                                  style={{ fontSize: "15px" }}
                                ></i>
                              </button>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center">
                        No Data Found !
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={total}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
      <CoinSeller />
    </>
  );
};

export default connect(null, { getCoinSellerHistory })(SellerTable);
