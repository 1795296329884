import React, { useEffect } from "react";

// js
import "../assets/js/main.min.js";

//router
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

// css
import "../assets/css/main.min.css";
import "../assets/css/custom.css";

// component
import Navbar from "../component/navbar/Navbar";
import Topnav from "../component/navbar/Topnav";

import Dashboard from "./Dashboard";

//loader
import Spinner from "./Spinner";
import SellerTable from "../component/table/SellerTable.js";
import UserHistory from "../component/table/UserHistory.js";
import History from "../component/table/History.js";
import DiamondShop from "../pages/DiamondShop.js";

const Admin = () => {
  const location = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === "/admin") {
      history.push("/admin/dashboard");
    } // eslint-disable-next-line
  }, []);

  return (
    <>
      <div class="page-container">
        <Navbar />
        <div class="page-content">
          <Topnav />
          <div class="main-wrapper">
            <Switch>
              <Route
                path={`${location.path}/dashboard`}
                exact
                component={Dashboard}
              />

              <Route
                exact
                path={`${location.path}/history`}
                component={History}
              />


              <Route
                exact
                path={`${location.path}/buyDiamonds`}
                component={DiamondShop}
              />

              <Route
                exact
                path={`${location.path}/user/history`}
                component={UserHistory}
              />
              <Route
                path={`${location.path}/userHistory`}
                exact
                component={SellerTable}
              />

            </Switch>
            <Spinner />
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
