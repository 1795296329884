import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// action
import {connect, useSelector } from "react-redux";
import { getSeller } from "../store/admin/action";

const Dashboard = (props) => {
  const { seller } = useSelector((state) => state.admin);

  
  useEffect(() => {
    props.getSeller()
  },[])

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3">Dashboard</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-2 col-sm-12">
      <Link to="/admin/history">
        <div class="row">
          <div class="col-lg-4">
            <div class="card stats-card">
              <div class="card-body pointer-cursor">
                <div class="stats-info">
                  <h5 class="card-title">
                    {seller?.coin ? seller?.coin : 0}
                    {/* <span class="stats-change stats-change-danger">-8%</span> */}
                  </h5>
                  <p class="stats-text">Total Balance</p>
                </div>
                <div class="stats-icon change-danger">
                  <i class="material-icons">people_alt</i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card stats-card">
              <div class="card-body pointer-cursor">
                <div class="stats-info">
                  <h5 class="card-title">
                    {seller?.spendCoin ? seller?.spendCoin : 0}
                    {/* <span class="stats-change stats-change-danger">-8%</span> */}
                  </h5>
                  <p class="stats-text">Spend Diamond</p>
                </div>
                <div class="stats-icon change-success">
                  <i class="material-icons">perm_identity</i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card stats-card">
              <div class="card-body pointer-cursor">
                <div class="stats-info">
                  <h5 class="card-title">
                    {seller?.receiveCoin ? seller?.receiveCoin : 0}
                  </h5>
                  <p class="stats-text">Receive Diamond</p>
                </div>
                <div class="stats-icon change-pink">
                  <i class="material-icons">group</i>
                </div>
              </div>
            </div>
          </div>
      
        </div>
        </Link>
      </div>
    </>
  );
};

export default connect(null ,{getSeller})(Dashboard);