import React from "react";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { createCoinData } from "../../store/seller/seller.action";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Tooltip,
} from "@material-ui/core";
import Cancel from "@material-ui/icons/Cancel";
import { useSelector } from "react-redux";
import * as ActionType from "../../store/seller/seller.type";
import { useEffect } from "react";

function CoinSeller(props) {
  const { dialog, dialogData } = useSelector((state) => state.sellerCoin);

  const { admin } = useSelector((state) => state.admin);

  const [userId, setUserId] = useState("");
  const [coin, setCoin] = useState(null);
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch({ type: ActionType.CLOSE_COIN_SELLER_DIALOGUE });
  };

  useEffect(() => {
    setCoin(null);
    setUserId("");
    setError({
      userId: "",
      coin: "",
    });
  }, [dialog]);

  useEffect(() => {
    window.onbeforeunload = handleClose();
  }, []);

  const handleSubmit = () => {
    let sellerCoin = parseInt(coin)
    if (!userId || ! sellerCoin || sellerCoin <= 0 )  {
      
      let error = {};
      if (!userId) error.userId = "userId Is Required!";
      if (!sellerCoin) error.coin = "Diamond Is Required!";
      if (sellerCoin <= 0) error.coin = "Invalid Value";
      return setError({ ...error });
    } else {
      let obj = {
        userId,
        coin: parseInt(coin), // Convert coin to a number
        coinSellerId: admin._id,
      };
  
      props.createCoinData(obj);
      handleClose();
    }
  };
  

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  return (
    <>
      <Dialog
        open={dialog}
        aria-labelledby="responsive-dialog-title"
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
        style={{
          zIndex: 9999999,
        }}
      >
        <DialogTitle id="responsive-dialog-title ">
          <span className="text-primary font-weight-bold h4 pb-0">
            Add Diamond
          </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
            color: "#664dc9",
          }}
        >
          <Tooltip title="Close" placement="right">
            <Cancel className="cancelButton" onClick={handleClose} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div class="modal-body pt-1 px-1 pb-3">
            <div class="d-flex flex-column">
              <form>
                <div class="form-group">
                  <div className="col-md-12 mt-2">
                    <label class="float-left dialog__input__title">
                      UserId
                    </label>
                    <input
                      type="text"
                      placeholder="userId"
                      class="form-control form-control-line mt-3 px-2"
                      value={userId}
                      onChange={(e) => {
                        setUserId(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            userId: "userId Is Required !",
                          });
                        } else {
                          return setError({
                            ...error,
                            userId: "",
                          });
                        }
                      }}
                      onKeyPress={handleKeyPress}
                    />

                    {error.userId && (
                      <div class="pl-1 text-left">
                        <Typography
                          variant="caption"
                          color="error"
                          style={{ fontFamily: "Circular-Loom" }}
                        >
                          {error.userId}
                        </Typography>
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 mt-2">
                    <label class="float-left dialog__input__title">Diamond</label>
                    <input
                      type="number"
                      min="0"
                      placeholder="Diamond"
                      className="form-control form-control-line mt-3 px-2"
                      value={coin}
                      onChange={(e) => {
                        setCoin(e.target.value);

                        if (!e.target.value) {
                          return setError({
                            ...error,
                            coin: "Diamond Is Required !",
                          });
                        } else if(e.target.value <=0){
                          return setError({
                            ...error,
                            coin: "Invalid Value !",
                          });
                        }
                         else {
                          return setError({
                            ...error,
                            coin: "",
                          });
                        }
                      }}
                      onKeyPress={handleKeyPress}
                    />

                    {error.coin && (
                      <div class="pl-1 text-left">
                        <Typography
                          variant="caption"
                          color="error"
                          style={{ fontFamily: "Circular-Loom" }}
                        >
                          {error.coin}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
                <DialogActions>
                  <button
                    type="button"
                    class="btn btn-success  px-3"
                    onClick={handleSubmit}
                  >
                    Insert
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger  px-3"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </DialogActions>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default connect(null, { createCoinData })(CoinSeller);
