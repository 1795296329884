import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//action
import { getShopDiamond, buyDiamond } from "../store/diamondShop/action";
import { getSeller } from "../store/admin/action";

//config
import { baseURL } from "../util/Config";

//routing
import { Link } from "react-router-dom";

//sweet alert
import { warningv2 } from "../util/Alert";
import { BUY_DIAMOND } from "../store/diamondShop/types";

const DiamondShop = (props) => {
    const dispatch = useDispatch()
    const [data, setData] = useState();

    const { seller } = useSelector((state) => state.admin);
    const shopDiamond = useSelector((state) => state.diamondShop.shopDiamond);

    useEffect(() => {
        props.getSeller()
      },[])
      useEffect(() => {
          props.getShopDiamond(); // eslint-disable-next-line
      }, []);

    useEffect(() => {
        setData(shopDiamond);
    }, [shopDiamond]);

    const handleBuyDiamond = (diamondData) => {
        const data = warningv2("Buy Diamond", `Purchase ${diamondData?.diamond || ''} diamonds?`);
        data.then((isBuy) => {
            if (isBuy) {
                buyDiamond(diamondData);
            }
        })
    }

    const buyDiamond = (diamondData) => {
        const data = {
            seller_id: seller._id,
            amount: diamondData.price,
            diamond_amount: diamondData.diamond,
            paymentFor: "sellerDiamond"
        };
        props.buyDiamond(data);
    }
    return (
        <div>
            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last">
                        <h3 className="mb-3 text-white">Buy Diamonds</h3>
                    </div>
                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav
                            aria-label="breadcrumb"
                            className="breadcrumb-header float-start float-lg-end"
                        >
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard" className="text-danger">
                                        Dashboard
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Buy Diamonds
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="main-wrapper">
                <div className="row">
                    {data?.length > 0 ? (
                        data.map((data, index) => {
                            return (
                                <>
                                    <div
                                        className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4"
                                        key={index}
                                    >
                                        <div className="card contact-card card-bg">
                                            <div className="card-body p-1">
                                                <div className="px-3 py-4">
                                                    <div className="col-4 ps-4 d-flex">
                                                        <img
                                                            src={
                                                                baseURL + data?.image
                                                            }
                                                            style={{
                                                                width: "135px",
                                                                height: "135px",
                                                                objectFit: "cover",

                                                            }}
                                                            alt=""
                                                            className="rounded-circle my-auto"
                                                            height={80}
                                                        />
                                                    </div>

                                                    <div
                                                        className="pe-4"
                                                        style={{
                                                            padding: 0,
                                                            paddingLeft: 5,
                                                        }}
                                                    >
                                                        <div className="px-3">

                                                            <div className="mt-2">
                                                                <h5 className="text-white">
                                                                    Diamonds: {data?.diamond}
                                                                </h5>
                                                            </div>

                                                            <div className="mt-2">
                                                                <h5 className="text-white">
                                                                    Price: {data?.price} PHP
                                                                </h5>
                                                            </div>
                                                        </div>

                                                        <div className="px-3">
                                                            <button type="submit" class="btn btn-danger m-b-xs" onClick={() => handleBuyDiamond(data)}>
                                                                Buy
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan="6" align="center">
                                Nothing to show!!
                            </td>
                        </tr>
                    )}
                </div>
            </div>
        </div>
    )

}

export default connect(null, { getShopDiamond, getSeller, buyDiamond })(
    DiamondShop
);