import React from "react";
import Card1 from "../../assets/images/card.jpeg";
import { warning } from "../../util/Alert";
// jquery
import $ from "jquery";
// redux
import { useDispatch, useSelector } from "react-redux";
// types
import { UNSET_ADMIN } from "../../store/admin/types";

//serverpath
import { baseURL } from "../../util/Config";
import { connect } from "react-redux";

import {getSeller} from "../../store/admin/action"
import { useEffect } from "react";

const Topnav = (props) => {
  const dispatch = useDispatch();
  const {seller} = useSelector((state) => state.admin);
  console.log('seller', seller)

  useEffect(() => {
    props.getSeller()
  },[])

  const handleDrawer = () => {
    $(".profile-drop-menu").toggleClass("show");
  };

  const closePopup = () => {
    $("body").removeClass("activity-sidebar-show");
  };

  const handleLogout = () => {
    const data = warning();
    data.then((isLogout) => {
      if (isLogout) {
        dispatch({ type: UNSET_ADMIN });
        window.location.href = "/";
      }
    });
  };
  console.log('seller', seller)

  return (
    <>
      <div class="page-header">
        <nav class="navbar navbar-expand-lg d-flex justify-content-between">
          <div class="header-title flex-fill">
            <a href={() => false} id="sidebar-toggle">
              <i data-feather="arrow-left"></i>
            </a>
          </div>
          <div class="flex-fill" id="headerNav">
            <ul class="navbar-nav">
              <li class="nav-item dropdown mb-2" onClick={handleDrawer}>
                  <img
                    src={seller?.image}
                    alt=""
                    style={{ width: "30px", height: "30px" }}
                  />
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div class="activity-sidebar-overlay"></div>
    </>
  );
};
export default connect(null ,{getSeller})(Topnav);
