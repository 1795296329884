import * as ActionType from "./history.type";

const initialState = {
  history: [],
  totalCoin: 0,
  total:0
};

export const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_ADMIN_HISTORY:
      console.log(action.payload.history);
      return {
        ...state,
        history: action.payload.history,
        totalCoin: action.payload.totalCoin,
        total: action.payload.total,
      };

    case ActionType.GET_USER_HISTORY:
      return {
        ...state,
        history: action.payload,
      };
    default:
      return state;
  }
};
